.section-header::before {
  content: "";
  background: url("../../media/hero/kota-wisata-landmark-transformed.webp");
  background-size: cover; /* Mengatur agar gambar mengisi seluruh area */
  background-position: center-top; /* Menempatkan gambar di tengah */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Meletakkan di belakang elemen lain */
  height: 100vh;

  /* Overlay hitam */
  background-blend-mode: multiply; /* Menggabungkan gambar dengan warna overlay */
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Warna hitam dengan transparansi 50% */
  @media (max-width: 1024px) {
    background-position: center;
  }
}
.section-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 12px;
  height: 100vh;
  color: #f1f1e6;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 95vh;
  }
  .container-header {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 33%;
    height: 100vh;
    padding: 2rem;
    background-blend-mode: multiply; /* Menggabungkan gambar dengan warna overlay */
    background-color: rgba(0, 0, 0, 0.7);

    @media (max-width: 768px) {
      padding: 1rem;
      height: fit-content;
      width: fit-content;
    }
    .section-title {
      font-size: 50px;
      @media (max-width: 768px) {
        font-size: 36px;
      }
    }
    .button-wa-promo {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        width: 60%;
        padding: 7px 9px;
        display: inline-block;
        cursor: pointer;
        border-radius: 9px;
        border: solid;
        border-width: 1px;
        background-color: #f1f1e6;
        /*WhatsAppgreen*/
        color: #3b3730;
        font-size: 16px;
        transition-duration: 0.3s;
        @media (max-width: 768px) {
          width: 100%;
        }
        &:hover {
          background-color: #3b3730;
          color: white;
          padding: 15px 0;
          transition-duration: 0.3s;
        }
      }
      span {
        margin-left: 6px;
      }
    }
  }
  h1 {
    font-size: 36px;
  }
  h1,
  h3 {
    margin: 0;
  }
}
