.section-about {
  color: #e0f1ff;
  text-align: center;
  background: #0e4274;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-about {
    width: 80%;
    padding: 24px;
    h1,
    p {
      margin: 0;
    }
    @media (max-width: 768px) {
      text-align: start;
      padding: 1rem;
      width: 90%;
    }
    .section-title {
      font-family: "Amiko", Sans-serif;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }
}
