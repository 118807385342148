.newlaunchcont {
  background-color: #0e4274;
  padding-top: 30px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  .center {
    color: white;
    width: 80%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
      gap: 0px;
    }
    gap: 30px;
    .brosur {
      width: 50%;
      text-align: right;
      @media (max-width: 768px) {
        width: 100%;
      }
      .img {
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .promoitem {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .title {
        font-size: 32px;
        margin-bottom: 20px;
      }
      .point {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .wabutton {
        margin-top: 25px;
        .button {
          width: 70%;
          padding: 7px 9px;
          display: inline-block;
          cursor: pointer;
          border-radius: 9px;
          border: solid;
          border-width: 1px;
          background-color: #f1f1e6;
          /*WhatsAppgreen*/
          color: #3b3730;
          font-size: 16px;
          transition-duration: 0.3s;

          &:hover {
            background-color: #3b3730;
            color: white;
            padding: 15px 0;
            transition-duration: 0.3s;
          }
        }
      }
    }
  }
}
